import { useField } from 'formik';
import React, { useState, useEffect } from 'react';
import { countryOptions } from '../../../config/config';
import { getAvailableInstallments } from '../../../utils/getAvailableInstallments';

const SelectQuote = ({ placeholderText, label, shouldFilterByCountry, country, paymentMode, ...props }) => {

   const [field, meta] = useField(props);
  const { error } = meta;
  
  const countryOption = countryOptions.find(c => c.value === country);
  const isoCode = countryOption ? countryOption.iso : '';
  // console.log({isoCode,countryOption});
  const filteredOptions = getAvailableInstallments(isoCode,paymentMode);
  
  return (
    <div className="field">
      <label htmlFor={props.id} className="label">
        {label}
      </label>
      <div className="control select">
        <select
          {...field}
          {...props}
          className={error ? 'input is-danger' : 'input'}
        >
          <option hidden>{placeholderText}</option>
          {Array.isArray(filteredOptions) &&
            filteredOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SelectQuote;
