/* eslint-disable react-hooks/exhaustive-deps */
import { useFormikContext } from 'formik';
import { useContext, useEffect, useState } from 'react';
import InputField from '../../PasarelaCobros/InputField';
import { AppContext } from '../../PasarelaCobros/Provider/StateProvider';
import Select from '../Select';
import { FormStep } from './MultiStep';
import withSpinner from '../Hoc/withSpinner';
import { calculateRecurrentPayAmounts,calculateAdvancedRecurrentPayAmounts } from '../../../logic/paymentInformation';
import { countryOptions,paymentOptions } from '../../../config/config';
import FileUpload from '../FileUpload';
import SelectQuote from '../SelectQuote';

const PaymentInformation = () => {
  
  const { appEnv,setAppEnv, totalPrice} = useContext(AppContext);
  const formik = useFormikContext();

  useEffect(() => {
    // console.log('PaymentInformation step', { appEnv });
    if (
      appEnv !== null &&
      appEnv?.contract !== null &&
      typeof appEnv?.contract !== 'undefined'
    ) {
      Object.keys(appEnv?.contract).map((key) => {
        const value =
          appEnv?.contract[key] !== null && appEnv?.contract[key]
            ? appEnv?.contract[key]
            : '';
        // console.log({ key, value });
        formik.setFieldValue(key, value);
      });
    }
  }, [appEnv, appEnv?.contract]);

  // console.log({appEnv});
  const [showCountOfInstallments, setShowCountOfInstallments] = useState(false);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState('');
  const [showPaymentDetails, setShowPaymentDetails] = useState(true);


  // console.log({formik});
  const handleModeChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const selectedText = selectedOption.text;

    formik.setFieldValue('payment_mode', selectedOption.value);

    setSelectedPaymentMode(selectedText);

    const isRecurrent = (selectedText.includes('recurrente') || selectedText.includes('cuotificado'));
    setShowCountOfInstallments(isRecurrent);

    //Reinicar los campos
    isRecurrent ? formik.setFieldValue('count_of_installments', 0): formik.setFieldValue('count_of_installments', 1);
    formik.setFieldValue('remaining_installment_count', 0);
    formik.setFieldValue('advance_payment_amount', 0);
    formik.setFieldValue('each_payment_amount', 0);
    formik.setFieldValue('adjustment_payment', 0);
    console.log({formik: formik.values.count_of_installments});

  };
  
  const handleAmountsPaymentsChange = (e) => {

    const selectedOption = e.target.options[e.target.selectedIndex];

    formik.setFieldValue('count_of_installments', selectedOption.value);
    formik.setFieldValue('remaining_installment_count', selectedOption.value -1);

    let countQuotes = 1; // Valor predeterminado
    const total = totalPrice;

    if (!isNaN(parseInt(selectedOption.text))) {
      countQuotes = parseInt(selectedOption.text);//ejecutarla funcion Number
    }
    if (selectedPaymentMode === 'Cobro total en un pago') {
      formik.setFieldValue('each_payment_amount', 1);
    }
    if(selectedPaymentMode === 'Cobro recurrente' || selectedPaymentMode === 'Cobro cuotificado' ) {
      const { quoteForMonth, adjustmentPayment } = calculateRecurrentPayAmounts(totalPrice, countQuotes);
      
      formik.setFieldValue('each_payment_amount', quoteForMonth);
      formik.setFieldValue('adjustment_payment', adjustmentPayment);
    }
    if(selectedPaymentMode === 'Cobro recurrente con parcialidad') {
      const {payPerMonthAdvance,firstQuoteDiscount,adjustmentPayment} = calculateAdvancedRecurrentPayAmounts(totalPrice,countQuotes);

      formik.setFieldValue('each_payment_amount', payPerMonthAdvance);
      formik.setFieldValue('advance_payment_amount', firstQuoteDiscount);
      formik.setFieldValue('adjustment_payment', adjustmentPayment);
    }
  };

  return (
    <>
      <FormStep stepNumber={5} stepName="Informacion de pago">
        <div className="grid-payment_information">
          <InputField
            label="Fecha de primer cobro"
            type="date"
            placeholder="--/--/----"
            id="date_first_payment"
            name="date_first_payment"
          />
          <Select
            label="Método de pago"
            options={paymentOptions}
            type="text"
            placeholderText="Seleccionar un metodo de pago"
            id="payment_method"
            name="payment_method"
            shouldFilterByCountry={true}
            country={appEnv.country}
          />
          <Select
            label="Modo de pago"
            options={[
              { name: 'Cobro cuotificado'},
              { name: 'Cobro total en un pago'},
              { name: 'Cobro recurrente'},
              { name: 'Cobro recurrente con parcialidad'},
            ]}
            type="text"
            placeholderText="Seleccionar un modo de pago"
            id="payment_mode"
            name="payment_mode"
            onChange={handleModeChange}
          />
          
           {showPaymentDetails && (
            <>
             <SelectQuote
              label="Numero de Cuotas"
              placeholderText="Seleccionar..."
              id="count_of_installments"
              name="count_of_installments"
              onChange={handleAmountsPaymentsChange}
              disabled={!showCountOfInstallments}
              country={appEnv.country}
              paymentMode={formik.values.payment_mode}
            />
            <InputField
              label="Monto de cada cuota"
              placeholder="Monto calculado"
              id="each_payment_amount"
              name="each_payment_amount"
              disabled
              />
              <InputField
                label="Cuotas restantes"
                placeholder="Cuotas restantes"
                id="remaining_installment_count"
                name="remaining_installment_count"
                disabled
              />
              {selectedPaymentMode === 'Cobro recurrente con parcialidad' && (
                 <InputField
                  label="Monto de anticipo"
                  type="number"
                  placeholder="Monto de anticipo"
                  id="advance_payment_amount"
                  name="advance_payment_amount"
                  disabled
                />
              )}
              <InputField
                label="Ajuste"
                type="text"
                placeholder=""
                id="adjustment_payment"
                name="adjustment_payment"
                disabled
                />
            </>
          )}
         
          <FileUpload
            label="Subir archivo"
            placeholderText="Seleccionar archivo"
            id="uploadFile"
            name="uploadFile"
            onChange={(event) => {
              formik.setFieldValue('uploadFile', event.currentTarget.files[0]);
            }}
          />
        </div>
      </FormStep>
    </>
  );
};

export default withSpinner(PaymentInformation);
