export const currencyOptions = [
  { value: 'Argentina',       currency: 'ARS', docType: 'DNI', iso: 'AR' },
  { value: 'Bolivia',         currency: 'BOB', docType: 'DNI' },
  { value: 'Chile',           currency: 'CLP', docType: 'RUT', iso: 'CL' },
  { value: 'Colombia',        currency: 'COP', docType: 'DNI' },
  { value: 'Costa Rica',      currency: 'CRC', docType: 'DNI', iso: 'CRC' },
  { value: 'Ecuador',         currency: 'USD', docType: 'NUI', iso: 'EC' },
  { value: 'El Salvador',     currency: 'USD', docType: 'DNI', iso: 'USD' },
  { value: 'Guatemala',       currency: 'GTQ', docType: 'DNI' },
  { value: 'Honduras',        currency: 'HNL', docType: 'DNI', iso: 'HNL' },
  { value: 'México',          currency: 'MXN', docType: 'RFC', iso: 'MX' },
  { value: 'Nicaragua',       currency: 'NIO', docType: 'DNI' },
  { value: 'Panamá',          currency: 'USD', docType: 'DNI', iso: 'USD' },
  { value: 'Paraguay',        currency: 'PYG', docType: 'DNI', iso: 'PYG' },
  { value: 'Perú',            currency: 'PEN', docType: 'DNI', iso: 'PEN' },
  { value: 'Uruguay',         currency: 'UYU', docType: 'NIE', iso: 'UY' },
  { value: 'Estados Unidos',  currency: 'USD', docType: 'DNI' },
  { value: 'Venezuela',       currency: 'USD', docType: 'DNI', iso: 'USD' },
];

export const countryOptions = [
  {
    idElement: 'pais_arg_input',
    img: 'argFlag',
    value: 'Argentina',
    iso: 'arg',
    disabled: true,
  },
  {
    idElement: 'pais_bol_input',
    img: 'bolFlag',
    value: 'Bolivia',
    iso: 'bol',
    disabled: true,
  },
  {
    idElement: 'pais_chi_input',
    img: 'chiFlag',
    value: 'Chile',
    iso: 'chi',
    disabled: false,
  },
  {
    idElement: 'pais_col_input',
    img: 'colFlag',
    value: 'Colombia',
    iso: 'col',
    disabled: true,
  },
  {
    idElement: 'pais_cos_input',
    img: 'cosFlag',
    value: 'Costa Rica',
    iso: 'cos',
    disabled: true,
  },
  {
    idElement: 'pais_ecu_input',
    img: 'ecuFlag',
    value: 'Ecuador',
    iso: 'ecu',
    disabled: true,
  },
  {
    idElement: 'pais_sal_input',
    img: 'salFlag',
    value: 'El Salvador',
    iso: 'sal',
    disabled: true,
  },
  {
    idElement: 'pais_gua_input',
    img: 'guaFlag',
    value: 'Guatemala',
    iso: 'gua',
    disabled: true,
  },
  {
    idElement: 'pais_hon_input',
    img: 'honFlag',
    value: 'Honduras',
    iso: 'hon',
    disabled: true,
  },
  {
    idElement: 'pais_mex_input',
    img: 'mexFlag',
    value: 'México',
    iso: 'mex',
    disabled: false,
  },
  {
    idElement: 'pais_nic_input',
    img: 'nicFlag',
    value: 'Nicaragua',
    iso: 'nic',
    disabled: true,
  },
  {
    idElement: 'pais_pan_input',
    img: 'panFlag',
    value: 'Panamá',
    iso: 'pan',
    disabled: true,
  },
  {
    idElement: 'pais_par_input',
    img: 'parFlag',
    value: 'Paraguay',
    iso: 'par',
    disabled: true,
  },
  {
    idElement: 'pais_per_input',
    img: 'perFlag',
    value: 'Perú',
    iso: 'per',
    disabled: true,
  },
  {
    idElement: 'pais_uru_input',
    img: 'uruFlag',
    value: 'Uruguay',
    iso: 'uru',
    disabled: true,
  },
  {
    idElement: 'pais_usa_input',
    img: 'usaFlag',
    value: 'Estados Unidos',
    iso: 'usa',
    disabled: true,
  },
];
export const paymentMethodOptions = [
  {
    idElement: 'med_tarjeta',
    name: 'med',
    name: 'Pagar con Tarjeta',
    classLabel: 'half',
  },
  {
    idElement: 'med_link',
    name: 'med',
    name: 'Compartir Link',
    classLabel: 'half',
  },
];

export const paymentModeOptions = [
  {
    idElement: 'mod_traditional',
    name: 'mod',
    value: 'Tradicional',
    classLabel: 'half',
  },
  {
    idElement: 'mod_suscrip',
    name: 'mod',
    value: 'Suscripción',
    classLabel: 'half',
  },
];

export const clientForm = [
  {
    idElement: 'tipoSuscripcion',
    value: '',
    label: 'Tipo de suscripcion',
    options: ['agregar suscripcion', 'modificar suscripcion'],
  },
  {
    idElement: 'numeroContrato',
    value: '',
    label: 'Número de Contrato',
    placeholder: 'Ingrese número del contrato (SO)',
  },
  {
    idElement: 'email',
    value: '',
    label: 'Correo Electronico',
    placeholder: 'Ingrese correo electronico del cliente',
  },
  {
    idElement: 'montoContrato',
    value: '',
    label: 'Monto total del contrato',
    placeholder: 'Ingrese monto total del contrato',
  },
  {
    idElement: 'cuotas',
    value: '',
    label: 'Cuotas',
    placeholder: 'Ingrese las cuotas',
  },
  {
    idElement: 'montoMensual',
    value: '',
    label: 'Monto a pagar por mes',
    placeholder: 'Monto a pagar por mes',
  },
  {
    idElement: 'compartirLink',
    value: '',
    label: 'Compartir Link por',
    options: [
      {
        idElement: 'compartirLink_email',
        value: '',
        label: 'Email',
        placeholder: 'Ingrese email',
      },
      {
        idElement: 'compartirLink_wpp',
        value: '',
        label: 'WhatsApp',
        placeholder: 'Ingrese telefono',
      },
    ],
  },
];

export const cardForm = [
  {
    idElement: 'numeroTarjeta',
    value: '',
    label: 'Numero de tarjeta',
  },
];

export const numberSOForm = [
  {
    idElement: 'numberSO',
    value: '',
    label: 'Numero de SO',
  },
];

export const sideItemOptions = [
  {
    step: 1,
    idElement: 'seleccion_pais',
    label: 'País',
    value: '',
    status: 'current',
  },
  {
    step: 2,
    idElement: 'seleccion_metPago',
    label: 'MÉTODO DE PAGO',
    value: '',
    status: '',
  },
  {
    step: 3,
    idElement: 'mod_payment',
    label: 'MODO DE PAGO',
    value: '',
    status: '',
  },
  {
    step: 4,
    idElement: 'seleccion_pais',
    label: 'DATOS DEL CLIENTE    ',
    value: '',
    status: '',
  },
  {
    step: 5,
    idElement: 'seleccion_pais',
    label: 'DATOS DE LA TARJETA',
    value: '',
    status: '',
  },
];

export const userFlow = {
  stepOne: {
    step: 1,
    label: 'country',
    value: '',
    isoRef: '',
  },
  stepTwo: {
    step: 2,
    label: 'payment_method',
    value: '',
  },
  stepThree: {
    step: 3,
    label: 'payment_mode',
    value: '',
  },
  stepFour: {
    step: 4,
    label: 'customer_data',
    value: '',
  },
  stepFive: {
    step: 5,
    label: 'card_data',
    value: '',
  },
};

// VentaPresencial
export const sideItemOptionsVP = [
  {
    step: 1,
    idElement: 'seleccion_pais',
    label: 'País',
    value: '',
    status: 'current',
  },
  {
    step: 2,
    idElement: 'create_lead',
    label: 'LEAD',
    value: '',
    status: '',
  },
  {
    step: 3,
    idElement: 'conversion_contact',
    label: 'CONTACTO',
    value: '',
    status: '',
  },
  {
    step: 4,
    idElement: 'select_course',
    label: 'CURSOS',
    value: '',
    status: '',
  },
   {
    step: 5,
    idElement: 'seleccion_pais',
    label: 'MODOS DE PAGO',
    value: '',
    status: '',
  },
  {
    step:6,
    idElement: 'seleccion_pais',
    label: 'FINALIZACION DE CONTRATO',
    value: '',
    status: '',
  },
];

export const userFlowFaceToFaceSale = {
  stepOne: {
    step: 1,
    label: 'country',
    value: '',
    isoRef: '',
  },
  stepTwo: {
    step: 2,
    label: 'create_lead',
    value: '',
  },
  stepThree: {
    step: 3,
    label: 'conversion_contact',
    value: '',
  },
};

export const leadForm = [
  {
    idElement: 'name',
    value: '',
    label: 'NOMBRE',
    placeholder: 'Ingrese nombre',
  },
  {
    idElement: 'username',
    value: '',
    label: 'APELLIDO',
    placeholder: 'Ingrese apellido',
  },
  {
    idElement: 'telephone',
    value: '',
    label: 'TELEFONO',
    placeholder: 'Ingrese e-mail',
  },
  {
    idElement: 'profession',
    value: '',
    label: 'PROFESION',
    placeholder: 'Ingrese profesion',
  },
  {
    idElement: 'speciality',
    value: '',
    label: 'ESPECIALIDAD',
    placeholder: 'Ingrese especialidad',
  },
  {
    idElement: 'email',
    value: '',
    label: 'E-MAIL',
    placeholder: 'Ingrese email',
  },
  {
    idElement: 'method_contact',
    value: '',
    label: 'METODO DE CONTACTO',
    placeholder: 'Seleccionar metodo de contacto',
  },
];

export const contactForm = [
  {
    idElement: 'dni',
    value: '',
    label: 'DNI',
    placeholder: 'Ingrese DNI',
  },
  {
    idElement: 'sex',
    value: '',
    label: 'SEXO',
    placeholder: 'Ingrese sexo',
  },
  {
    idElement: 'date_of_birth',
    value: '',
    label: 'FECHA DE NACIMIENTO',
    placeholder: 'Ingrese fecha de nacimiento',
  },
  {
    idElement: 'country',
    value: '',
    label: 'PAIS',
    placeholder: 'Ingrese pais',
  },
  {
    idElement: 'province_state',
    value: '',
    label: 'PROVINCIA/ESTADO',
    placeholder: 'Ingrese provincia o estado',
  },
  {
    idElement: 'postal_code',
    value: '',
    label: 'CODIGO POSTAL',
    placeholder: 'Ingrese codigo postal',
  },
  {
    idElement: 'street',
    value: '',
    label: 'DIRECCION',
    placeholder: 'Ingrese direccion',
  },
  {
    idElement: 'locality',
    value: '',
    label: 'LOCALIDAD',
    placeholder: 'Ingrese localidad',
  },
  // {
  //   idElement: 'registration_number',
  //   value: '',
  //   label: 'NUMERO DE MATRICULA',
  //   placeholder: 'Ingrese numero de matricula',
  // },
  {
    idElement: 'area_of_work',
    value: '',
    label: 'AREA DE TRABAJO',
    placeholder: 'Ingrese area de trabajo',
  },
  {
    idElement: 'training_interest',
    value: '',
    label: 'INTERES DE FORMACION',
    placeholder: 'Ingrese interes de formacion',
  },
];

export const paymentInformation = [
  {
    idElement: 'payment_method',
    value: '',
    label: "METODO DE PAGO",
    placeholder: 'Seleccionar un metodo de pago',
  },
  {
    idElement: 'payment_mode',
    value: '',
    label: "MODO DE PAGO",
    placeholder: 'Seleccionar un modo de pago',
  },
  {
    idElement: 'date_first_payment',
    value: '',
    label: "FECHA DE PRIMER COBRO",
    placeholder: '--/--/----',
  },
  {
    idElement: 'count_of_installments',
    value: '',
    label: "NUMERO DE COBRO",
    placeholder: 'Seleccionar el número de cuotas',
  },
  {
    idElement: 'remaining_installment_count',
    value: '',
    label: "CUOTAS RESTANTES",
    placeholder: 'Cuotas Restantes',
  },
  {
    idElement: 'advance_payment_amount',
    value: '',
    label: "CUOTAS RESTANTES",
    placeholder: 'Cuotas Restantes',
  },
];

export const paymentOptions = [
  {
    name: 'Debito en cuenta',
    allowedCountries: [
      'arg',
    ]
  },
  {
    name: 'Efectivo',
    allowedCountries: [
      'arg',
    ]
  },
   { name: 'Lapos web',
    allowedCountries: [
      'arg',
    ]
  },
  {
    name: 'Mercado Pago',
    allowedCountries: [
      'arg',
      'chi',
      'mex'
    ]
  },
  {
    name: 'Payway',
    allowedCountries: [
      'arg',
    ]
  },
  { name: 'Prisma',
    allowedCountries: [
      'arg',
    ]
  },
  {
    name: 'Transferencia bancaria',
    allowedCountries: [
      'arg',
      'mex',
      'chi',
    ]
  },
  {
    name: 'Rebill',
    allowedCountries: [
      'chi',
      'mex',
    ]
  },
  {
    name: 'Stripe',
    allowedCountries: [
      'chi',
      'mex',]
  },
  {
    name: 'Transbank',
    allowedCountries: [
      'chi',
    ]
  },
  {
    name: 'WebPay',
    allowedCountries: [
      'chi',
    ]
  },
  {
    name: 'CTC',
    allowedCountries: [
      'mex',
    ]
  },
];
