export const calculateAdvancedRecurrentPayAmounts = (total,countQuotes) => {
    const quoteForMonth = Math.floor(total / countQuotes);
      const remainingQuotes = countQuotes === 1 ? 1 : countQuotes - 1;

      const firstQuoteDiscount = Math.floor(quoteForMonth / 2);
      const remainingAmountToPay = total - firstQuoteDiscount;
      const payPerMonthAdvance = Math.floor(remainingAmountToPay / remainingQuotes);
      const adjustmentPayment = parseFloat(
        ((payPerMonthAdvance * remainingQuotes) + firstQuoteDiscount - total).toFixed(2),
      );

      const infoPayment = {
        remainingQuotes,
        firstQuoteDiscount,
        remainingAmountToPay,
        payPerMonthAdvance,
        adjustmentPayment,
      };
    // console.log({ infoPayment });
    return infoPayment;
};

export const calculateRecurrentPayAmounts = (total,countQuotes) => {
    const quoteForMonth = Math.floor(total / countQuotes);
      const remainingQuotes = countQuotes === 1 ? 1 : countQuotes - 1;
      const adjustmentPayment = parseFloat(
        ((quoteForMonth * countQuotes) - total).toFixed(2)
      );
      
      const infoPayment = {
        quoteForMonth,
        remainingQuotes,
        adjustmentPayment,
      };
      // console.log({ infoPayment });
    return infoPayment;
};