export const getAvailableInstallments = (countryCode, paymentMode) => {
    switch (countryCode) {
        case 'arg': 
            return paymentMode.includes('cuotificado') ? cuotificadoQuotes(1, 12):
                filteredQuotes([{ name: 1 }, { name: 3 }, { name: 6 }, { name: 9 }, { name: 12 }, { name: 18 }, { name: 24 }], paymentMode);
        case 'mex': 
            return paymentMode.includes('cuotificado') ? cuotificadoQuotes(1, 12):
                filteredQuotes([{ name: 1 }, { name: 3 }, { name: 6 }, { name: 9 }, { name: 12 }], paymentMode);
        case 'chi': 
            return paymentMode.includes('cuotificado') ? cuotificadoQuotes(1, 12):
                filteredQuotes([{ name: 1 }, { name: 3 }, { name: 6 }, { name: 8 }], paymentMode);
        case 'ecu':
            return paymentMode.includes('cuotificado') ? cuotificadoQuotes(1, 15):
                filteredQuotes([{ name: 1 }, {name: 3}, {name: 6}, {name: 9} , {name: 12}], paymentMode);
        default:
            return filteredQuotes([{ name: 1 }, {name: 3}, {name: 6}, {name: 9} , {name: 12}], paymentMode);
    }
};

const filteredQuotes = (quotes, paymentMode) => {
    let [option1, ...options] = quotes;
    const finalQuotes = paymentMode.includes('parcialidad') ? options : [option1, ...options];

    return finalQuotes;
}

function cuotificadoQuotes(start, end) {
  // Validate that start and end are numbers and start is less than or equal to end
  if (typeof start !== 'number' || typeof end !== 'number' || start > end) {
    throw new Error('Los parámetros deben ser números y "start" debe ser menor o igual a "end".');
  }

  // Create the array with objects
  const finalQuotes = [];
  for (let i = start; i <= end; i++) {
    finalQuotes.push({ name: i });
    console.log({ name: i });
  }
  return finalQuotes;
}

// // Example of usage
// const result = cuotificadoQuotes(1, 15);
// console.log(result);
