import asesorComercial from './asesor-comercial.jpg';
import asesorCobranzas from './asesor-cobranzas.jpg';
import argFlag from './countryFlags/argentina.svg';
import bolFlag from './countryFlags/bolivia.svg';
import chiFlag from './countryFlags/chile.svg';
import colFlag from './countryFlags/colombia.svg';
import cosFlag from './countryFlags/costa-rica.svg';
import ecuFlag from './countryFlags/ecuador.svg';
import salFlag from './countryFlags/el-salvador.svg';
import usaFlag from './countryFlags/estados-unidos.svg';
import guaFlag from './countryFlags/guatemala.svg';
import honFlag from './countryFlags/honduras.svg';
import mexFlag from './countryFlags/mexico.svg';
import nicFlag from './countryFlags/nicaragua.svg';
import panFlag from './countryFlags/panama.svg';
import parFlag from './countryFlags/paraguay.svg';
import perFlag from './countryFlags/peru.svg';
import uruFlag from './countryFlags/uruguay.svg';
import mp from './metPago/mp.svg';
import st from './metPago/stripe.svg';
import pp from './metPago/paypal.svg';
import df from './metPago/datafast.jpg';
import ba from './metPago/banorte.svg';
import logo from './msk-logo.svg';
import save from './icons/save.svg';

const IMAGES = {
  asesorComercial,
  asesorCobranzas,
  argFlag,
  bolFlag,
  chiFlag,
  colFlag,
  cosFlag,
  ecuFlag,
  salFlag,
  usaFlag,
  guaFlag,
  honFlag,
  mexFlag,
  nicFlag,
  panFlag,
  parFlag,
  perFlag,
  uruFlag,
  mp,
  df,
  ba,
  st,
  pp,
  logo,
  save,
};

export default IMAGES;
