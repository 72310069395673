import { useField } from 'formik';
import React from 'react';

const FileUpload = ({ label, placeholderText, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { error } = meta;
  const { setValue } = helpers;

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    // Puedes realizar acciones con el archivo cargado, como guardar su referencia, procesarlo, etc.
    setValue(uploadedFile); // Guardar el archivo en el estado de Formik
  };

  return (
    <div className="field">
      <label htmlFor={props.id} className="label">
        {label}
      </label>
      <div className="control">
        <input
          type="file"
          id={props.id}
          name={props.name}
          accept=".png"
          onChange={handleFileChange}
          className={error ? 'input is-danger' : 'input'}
        />
      </div>
    </div>
  );
};

export default FileUpload;
