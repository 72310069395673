import { useField } from 'formik';
import React, { useState, useEffect } from 'react';
import { countryOptions } from '../../../config/config';

const Select = ({ options, placeholderText, label, shouldFilterByCountry, country, ...props }) => {
  const [field, meta] = useField(props);
  const { error } = meta;

  const filteredOptions =
    shouldFilterByCountry ? options.filter((option) => {
      const countryOption = countryOptions.find(c => c.value === country);
      const isoCode = countryOption ? countryOption.iso : '';
      // console.log({isoCode,countryOption});
      return option.allowedCountries.includes(isoCode) && !option.disabledCountries?.includes(isoCode);
    }) : options;
  
  return (
    <div className="field">
      <label htmlFor={props.id} className="label">
        {label}
      </label>
      <div className="control select">
        <select
          {...field}
          {...props}
          className={error ? 'input is-danger' : 'input'}
        >
          <option hidden>{placeholderText}</option>
          {Array.isArray(options) &&
            filteredOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Select;
