import axios from 'axios';
import { useContext, useState } from 'react';
import { useParams } from 'react-router';
import { AppContext } from '../../PasarelaCobros/Provider/StateProvider';
import { useSwal } from './useSwal';
import api from '../Services/api';
import { generateURL } from '../../../lib/generateURL';

const apiStepConversionContract = generateURL(`/api/db/stepConversionContract`);
const apiCreateSaleZohoCRM = generateURL(`/api/createSaleZohoCRM`);
const apiStepPaymentInformation = generateURL(`/api/db/stepPaymentInformation`);
const apiUpdateSaleZohoCRM = generateURL('/api/updateSaleZohoCRM');


export const useContract = () => {
  const [fetching, setFetching] = useState(false);
  const [completeData, setCompleteData] = useState(null);
  const { id } = useParams();
  const { modalAlert } = useSwal();
  const ctx = useContext(AppContext);

  const createContractSales = async (values) => {
    console.log('createContractSales', { values });
    setFetching(true);
    try {

      const body = {
        idPurchaseProgress: id,
        products: ctx.selectedCourses,
        step_number: 5,
      }

      const data = await api.createContractCRM(apiStepConversionContract, body)
      console.log({ data });
      const { contract, progress, products } = data;
      await createContractCRM();

      ctx.setAppEnv((prevEnv) => ({
        ...prevEnv,
        contract: { ...contract },
        ...progress,
        products,
      }));

    } catch (e) {
      console.log({ e });
      const { message } = e.response.data;
      modalAlert(message, 'error');
      setFetching(false);
    }
  };

  const createContractCRM = async () => {
    // console.log(responseCreateLeadSales);
    try {
      const response = await axios.post(
        apiCreateSaleZohoCRM,
        {
          idPurchaseProgress: id,
          discount: ctx.discount,
          contract_id: ctx.discount,
        },
        { headers: { Authorization: ctx.tokenLogin } }
      );
      setCompleteData(response.data);
      console.log({ response });
    } catch (e) {
      console.log({ e });
      const { message } = e.response.data;
      modalAlert(message, 'error');
      setFetching(false);
    } finally {
      setFetching(false);
    }
  };

  const stepPaymentInformation = async (values) => { 
    console.log('stepPaymentInformation', { values });
    setFetching(true);
    try {

      const body = {
        idPurchaseProgress: id,
        step_number: 6,
        ...values
      }

      const data = await api.updateContract(apiStepPaymentInformation, body)
      console.log({ data });
      // const { contract, progress } = data;
      const { progress } = data;
      await updateSaleZohoCRM(values);

      ctx.setAppEnv((prevEnv) => ({
        ...prevEnv,
        // contract: { ...contract },
        ...progress,
      }));

    } catch (e) {
      console.log({ e });
      const { message } = e.response.data;
      modalAlert(message, 'error');
      setFetching(false);
    }
  }; 

  const updateSaleZohoCRM = async (values) => {
    console.log('updateSaleZohoCRM', { values });
    setFetching(true);
    try {
      
      const resUpdateSaleZohoCRM = await axios.post(
        apiUpdateSaleZohoCRM,
        {
          ...values,
          step_number: 6,
          idProgress: ctx.appEnv.id,
          adjustmentPayment: ctx.appEnv.adjustmentPayment ?? null,
        },
        { headers: { Authorization: ctx.tokenLogin,'Content-Type': 'multipart/form-data' } }
      );
    } catch (e) {
      console.log({ e });

      const { message } = e.data;
      modalAlert(message, 'error');
    setFetching(false);
    } finally {
      setFetching(false);
    }
  };

  return { fetching, completeData, createContractSales, updateSaleZohoCRM, stepPaymentInformation };
};
